import request from '@/utils/request'

export function getProcurementList(data) {
  return request({
    url: 'api/procurement/GetProcurementPage',
    method: 'post',
    data,
  })
}

export function getSupplierProcurementList(data) {
  return request({
    url: 'api/procurement/GetSupplierProcurementPage',
    method: 'post',
    data,
  })
}

export function getProcurementState() {
  return request({
    url: 'api/procurement/GetProcurementStateOptions',
    method: 'get',
  })
}

export function addNewProcurement(data) {
  return request({
    url: 'api/procurement/AddNewProcurement',
    method: 'post',
    data,
  })
}

export function getProcurementInfo(id) {
  return request({
    url: 'api/procurement/GetProcurementInfo',
    method: 'get',
    params: { id },
  })
}

export function getSupplierProcurementInfo(id) {
  return request({
    url: 'api/procurement/GetSupplierProcurementInfo',
    method: 'get',
    params: { id },
  })
}

export function upProcurementAudite(orderId) {
  return request({
    url: 'api/procurement/UpAudite',
    method: 'post',
    params: { orderId },
  })
}

export function deleteProcurement(id) {
  return request({
    url: 'api/procurement/DeleteProcurement',
    method: 'get',
    params: { id },
  })
}

// Audit
export function auditProcurement(orderId, data) {
  return request({
    url: 'api/procurement/Audit',
    method: 'post',
    params: { orderId },
    data,
  })
}

// Update
export function updataProcurement(id, data) {
  return request({
    url: 'api/procurement/Update',
    method: 'post',
    params: { id },
    data,
  })
}

//ChangeOrderCheck
export function changeOrderCheck(orderId) {
  return request({
    url: 'api/procurement/ChangeOrderCheck',
    method: 'get',
    params: { orderId },
  })
}

// DownLoadProcurement
export function downLoadProcurement(id) {
  return request({
    url: 'api/procurement/DownLoadProcurement',
    method: 'get',
    params: { id },
    responseType: 'blob',
  })
}

//DeliveryGoods
export function deliveryGoods(data) {
  return request({
    url: 'api/procurement/DeliveryGoods',
    method: 'post',
    data,
  })
}

//DownLoadAdminProcurement
export function downLoadAdminProcurement(id) {
  return request({
    url: 'api/procurement/DownLoadAdminProcurement',
    method: 'get',
    params: { id },
    responseType: 'blob',
  })
}

export function getReporProductNumber(filter) {
  return request({
    url: 'api/procurement/GetReportProductSaleNumber',
    method: 'post',
    data: filter,
  })
}


export function getProductTimeSendNumber(filter) {
  return request({
    url: 'api/procurement/GetProductTimeSendNumber',
    method: 'post',
    data: filter,
  })
}