// 数组对象求和
export function iosOnFocus(event){
    const userAgent = navigator.userAgent || navigator.vendor || window
    // this.$message.success(userAgent)
    let isIOS = /iPad|iPhone|iPod|Mac OS/.test(userAgent) && !window.NSStream
    // this.$message.success(isIOS)
    if(isIOS){
        this.$nextTick(()=>{
            event.target.scrollIntoView();
            const input = event.target;
            if(input){
                input.focus();
            }
        })
    }
}