import request from '@/utils/request'

const URL = {
  getSuppliers: '/api/supplier/GetSupplierList',
  getSupplier: '/api/supplier/GetSupplier',
  addSupplier: '/api/supplier/AddSupplier',
  updateSupplier: '/api/supplier/UpdateSupplier',
  deleteSupplier: '/api/supplier/DeleteSuplier',
}

export function getAll() {
  return request({
    url: '/api/supplier/getAll',
    method: 'get',
  })
}

// 获取分页供应商信息
export function getSuppliers(data) {
  return request({
    url: URL.getSuppliers,
    method: 'post',
    data,
  })
}

// 获取当前供应商详情
export function getSupplier(id) {
  return request({
    url: URL.getSupplier,
    method: 'get',
    params: { id },
  })
}

// 新增供应商
export function addSupplier(data) {
  return request({
    url: URL.addSupplier,
    method: 'post',
    data,
  })
}

// 编辑供应商
export function updateSupplier(id, data) {
  return request({
    url: URL.updateSupplier,
    method: 'post',
    params: { id },
    data,
  })
}

// 删除供应商
export function deleteSupplier(id) {
  return request({
    url: URL.deleteSupplier,
    method: 'get',
    params: { id },
  })
}

export function addSupplierProduct(supplierId, productId) {
  return request({
    url: '/api/supplier/addSupplierProduct',
    method: 'get',
    params: { supplierId, productId },
  })
}
